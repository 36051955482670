import {InputLabelProps, TextFieldProps} from '@mui/material'
import classNames from 'classnames'
import i18next from 'i18next'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import React, {forwardRef, useCallback, useEffect, useRef, useState} from 'react'
import {makeStyles} from 'tss-react/mui'

import {InputTextField} from '../InputTextField/InputTextField'

const useStyles = makeStyles<{readonlyMode: boolean | undefined; newInput: boolean | undefined}>()(
  (_, {readonlyMode, newInput}) => ({
    phoneNumberTextField: {
      border: '1.5px solid rgba(0, 39, 77, 0.15)',
      borderRadius: '4px',
      '& .MuiInputBase-root': {
        height: '46px',
        border: 'none',
        borderRadius: '4px',
        background: newInput
          ? '#FFFFFF'
          : 'linear-gradient(0deg, #FBFBFC, #FBFBFC),linear-gradient(0deg, rgba(0, 39, 77, 0.15), rgba(0, 39, 77, 0.15))',
        '&.Mui-focused': {
          border: 'none'
        },
        '&.Mui-disabled': {
          opacity: 0.5
        }
      },
      '& .MuiInputBase-input': {
        padding: '14px 0',
        '&::placeholder': {
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '22px',
          color: '#54708C',
          opacity: 1
        }
      },
      '& .iti': {
        width: '100%',
        display: 'flex'
      },
      '& .iti input': {
        width: 'calc(100% - 36px)',
        paddingLeft: newInput ? '8px' : '16px !important'
      },
      '& .iti div': {
        display: readonlyMode ? 'none' : 'flex',
        gap: newInput ? 0 : '12px'
      },
      '& .iti.iti--allow-dropdown input': {
        width: 'calc(100% - 58px)'
      },
      '& .iti__flag-container': {
        position: 'relative',
        borderRight: newInput ? undefined : '1.5px solid rgba(0, 39, 77, 0.15)',
        padding: 0
      },
      '& .iti__selected-flag': {
        background: 'none',
        paddingLeft: newInput ? '18px' : 0
      }
    }
  })
)

export interface SelectedValues {
  inputValue: string
  selectedCountryCode: string
}

interface PhoneNumberTextFieldProps {
  className?: string
  onPhoneChange?: (
    phoneNumber: string,
    isValidNumber: boolean,
    itiGetCountry?: intlTelInput.CountryData
  ) => void
  options?: intlTelInput.Options
  InputLabelProps?: Partial<InputLabelProps>
  readonlyMode?: boolean
  filled?: boolean
  newInput?: boolean
  keepDialCode?: boolean
}

export const PhoneNumberTextField = forwardRef<
  HTMLInputElement,
  TextFieldProps & PhoneNumberTextFieldProps
>(
  (
    {
      className,
      onPhoneChange,
      options = {},
      InputLabelProps,
      readonlyMode,
      newInput,
      keepDialCode,
      ...otherProps
    },
    ref
  ) => {
    const {classes} = useStyles({readonlyMode, newInput})
    const itiRef = useRef<intlTelInput.Plugin>()
    const inputRef = useRef<HTMLInputElement>()
    const [selectedCountryDialCode, setSelectedCountryDialCode] = useState('')

    const isPhoneNumberValid = () => {
      const itiGetNumber = itiRef.current?.getNumber() ?? ''
      const isValidNumber = itiRef.current?.isValidNumber() ?? false
      return isValidNumber || !itiGetNumber
    }

    const handleCountryChange = useCallback(() => {
      if (!onPhoneChange) {
        return
      }
      const itiGetNumber = itiRef.current?.getNumber() ?? ''
      const itiGetCountry = itiRef.current?.getSelectedCountryData()

      onPhoneChange(itiGetNumber, isPhoneNumberValid(), itiGetCountry)
    }, [onPhoneChange])

    const localeToIso = (userLocale: string) => {
      if (!userLocale.includes('-')) {
        return userLocale
      }
      return userLocale.split('-')[1]
    }

    const getUserCountryCode = useCallback((callback: (countryCode: string) => void) => {
      try {
        return callback(localeToIso(i18next.language))
      } catch (err) {
        console.log('TCL: getUserCountryCode -> err', err)
      }
    }, [])

    useEffect(() => {
      const defaults: intlTelInput.Options = {
        preferredCountries: ['us', 'ca', 'ru', 'nl', 'gb', 'pl', 'de', 'au', 'ba', 'hk', 'ge'],
        autoPlaceholder: 'off',
        initialCountry: 'auto',
        geoIpLookup: getUserCountryCode,
        separateDialCode: true
      }

      if (inputRef && inputRef.current) {
        // dont remove try catch, app will crash if initializing fails
        try {
          itiRef.current = intlTelInput(inputRef.current, {
            ...defaults,
            ...options
          })
        } catch (e) {
          console.error(e)
        }
      }

      // @ts-ignore
      if (!window.intlTelInputUtils) {
        void import('intl-tel-input/build/js/utils').then(() => {
          ;(itiRef?.current as intlTelInput.Plugin & {handleUtils: () => void})?.handleUtils()
        })
      }

      return () => {
        itiRef.current?.destroy()
      }
    }, [readonlyMode])

    useEffect(() => {
      if (options.initialCountry) {
        itiRef.current?.setCountry(options.initialCountry)
        handleCountryChange()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.initialCountry])

    useEffect(() => {
      const inputEl = inputRef.current
      inputEl?.addEventListener('countrychange', handleCountryChange)
      return () => {
        inputEl?.removeEventListener('countrychange', handleCountryChange)
      }
    }, [handleCountryChange])

    useEffect(() => {
      setSelectedCountryDialCode(itiRef.current?.getSelectedCountryData().dialCode ?? '')
    }, [readonlyMode])

    useEffect(() => {
      if (
        !readonlyMode &&
        selectedCountryDialCode !== itiRef.current?.getSelectedCountryData().dialCode &&
        !itiRef.current?.getNumber().includes(itiRef.current?.getSelectedCountryData().dialCode)
      ) {
        setSelectedCountryDialCode(itiRef.current?.getSelectedCountryData().dialCode ?? '')

        const itiGetNumber = '+' + (itiRef.current?.getSelectedCountryData().dialCode ?? '')

        if (!onPhoneChange || isNaN(Number(itiGetNumber)) || !selectedCountryDialCode) {
          return
        }
        onPhoneChange(itiGetNumber, isPhoneNumberValid())
      }
    }, [itiRef.current?.getSelectedCountryData().dialCode])

    const isNumberValid = isPhoneNumberValid()

    const handleValue = (value?: string) => {
      const phoneNumber = value?.replace(
        '+' + itiRef.current?.getSelectedCountryData().dialCode,
        ''
      )

      return phoneNumber?.startsWith('00')
        ? phoneNumber?.replace('00', '+')
        : keepDialCode
          ? value
          : value?.replace('+' + itiRef.current?.getSelectedCountryData().dialCode, '')
    }
    return (
      <InputTextField
        type="tel"
        autoComplete="tel"
        error={!isNumberValid}
        value={handleValue(itiRef.current?.getNumber())}
        onChange={handleCountryChange}
        inputRef={inputRef}
        className={classNames(classes.phoneNumberTextField, className)}
        InputLabelProps={{
          ...InputLabelProps
        }}
        {...otherProps}
      />
    )
  }
)
