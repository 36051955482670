import {FormControlLabel, Switch} from '@mui/material'
import clsx from 'clsx'
import React from 'react'

import {useStyles} from './FormToggle.styles'

type FormToggleProps = {
  toggleKey: string
  checked: boolean
  onChange: (checked: boolean) => void
  label: string
  disabled?: boolean
  'data-test-id'?: string
}

export const FormToggle = ({
  toggleKey,
  disabled,
  onChange,
  checked,
  label,
  'data-test-id': dataTestId
}: FormToggleProps) => {
  const {classes} = useStyles()
  return (
    <FormControlLabel
      value={toggleKey}
      disabled={disabled}
      control={
        <Switch
          color="primary"
          checked={checked}
          disabled={disabled}
          onChange={(_, checked) => onChange(checked)}
          data-test-id={dataTestId}
          classes={{
            track: clsx({
              [classes.trackEnabled]: checked
            })
          }}
        />
      }
      label={label}
      labelPlacement="end"
      data-test-id={`${dataTestId}-label`}
    />
  )
}
