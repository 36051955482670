import {Box} from '@mui/material'
import clsx from 'clsx'
import React from 'react'

import {useStyles} from './InputMessage.styles'

import {Typography, useBreakPoints} from '@hconnect/uikit'

type InputMessageProps = {
  message: string
  startIcon?: React.ReactNode
  variant?: 'error' | 'info' | 'warning'
  actionItem?: React.ReactNode
  'data-test-id'?: string
}
export const InputMessage = ({
  message,
  variant = 'error',
  startIcon,
  actionItem,
  'data-test-id': dataTestId
}: InputMessageProps) => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs'].includes(screenSizes)
  const {classes} = useStyles()

  const handleVariantColor = () => {
    switch (variant) {
      case 'info':
        return '#016AD4'
      case 'warning':
        return '#EE8B00'
      default:
        return 'rgba(218, 9, 1, 1)'
    }
  }
  return (
    <Box
      className={clsx(classes.inputMessageContainer, classes[variant] || classes.error)}
      data-test-id={dataTestId || `input-${variant}-message`}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '12px',
        flexDirection: isMobile ? 'column' : 'row'
      }}
    >
      <Box style={{display: 'flex', gap: '12px', alignItems: 'center'}}>
        <Box style={{color: handleVariantColor()}}>{startIcon}</Box>
        <Typography style={{color: '#00274D', fontSize: '16px'}}>{message}</Typography>
      </Box>
      {actionItem}
    </Box>
  )
}
